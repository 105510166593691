
import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    collection: {
      fetchListLoading: false,
    },
    userInfo: {},
  },
  reducers: {
    setFetchListLoading(state, action) {
      state.collection.fetchListLoading = action.payload
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload
    },
  },
})

export const {
  setFetchListLoading,
  setUserInfo,
} = commonSlice.actions

export default commonSlice.reducer