import { createSlice } from '@reduxjs/toolkit'
import { getChainInfo } from '@/utils/networks'

const defaultChainId = 1
const defaultChain = getChainInfo(defaultChainId)

export const chainSlice = createSlice({
  name: 'chain',
  initialState: {
    visitChainId: defaultChainId, //当前访问的chain，右上角链的显示
    visitChain: defaultChain,
    realChainId: defaultChainId, // 真实用于交易的链id，collection、items链接中携带的id
    realChain: defaultChain,
    switchMarketChain: { //切换市场的链
      show: false,
    },
    unavailableChain: { // 不可使用链
      show: false,
    },
  },
  reducers: {
    setVisitChain(state, action) {
      const chainInfo = getChainInfo(action.payload)
      state.visitChainId = chainInfo?.chainId
      state.visitChain = chainInfo

      localStorage.setItem('chain', chainInfo?.chainId)
    },
    setRealChain(state, action) {
      state.realChainId = action.payload
      state.realChain = getChainInfo(action.payload)
    },
    setSwitchMarketChain(state, action) {
      state.switchMarketChain.show = action.payload
    },
    setUnavailableChain(state, action) {
      state.unavailableChain.show = action.payload
    },
  },
})

export const {
  setVisitChain,
  setRealChain,
  setSwitchMarketChain,
  setUnavailableChain,
} = chainSlice.actions

export default chainSlice.reducer