import { createContext, useContext } from 'react'
import useCart from '@/hooks/useCart'

const AppContext = createContext({})

export function AppWrapper({ children }) {
  const { cartState, cartDispatch } = useCart()
  let sharedState = { state: { cartState }, cartDispatch }

  return (
    <AppContext.Provider value={sharedState}>
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}

export default AppContext
