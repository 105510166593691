import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { ThemeProvider } from '@mui/material/styles'
import '../assets/index.css'
import '../assets/RichEditor.css'
import CssBaseline from '@mui/material/CssBaseline'
import { CacheProvider } from '@emotion/react'
import theme from '../src/themes/theme'
import createEmotionCache from '../src/createEmotionCache'
import { AppWrapper } from '@/utils/context'
import store from '@utils/store'
import { Provider } from 'react-redux'
import { isProduction } from '@utils/utils'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

import { connectorsForWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet, goerli, bsc, bscTestnet } from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { useAccount } from 'wagmi'
import '@rainbow-me/rainbowkit/styles.css'

import { ethwChain, ipoChain } from '@/utils/networks.js'

const _chains = isProduction() ? [mainnet, bsc, ethwChain, ipoChain]
  : [mainnet, goerli, bscTestnet, ethwChain, ipoChain]

const alchemyKey = isProduction()
  ? '8CjGbGbtrAI1zB7YuLZujI6SJVGKOerP'
  : 'HW1kQ7rfo83a9neLIJOaaW_FczMJFqv8'

const { chains, provider } = configureChains(_chains, [
  alchemyProvider({
    apiKey: alchemyKey, //process.env.REACT_APP_ALCHEMY_ID,
  }),
  publicProvider(),
])

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [metaMaskWallet({ chains, shimDisconnect: true }), walletConnectWallet({ chains })],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
})

export default function MyApp(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const Layout = Component.layout || (({ children }) => <>{children}</>)

  const { address } = useAccount()
  const addressRef = useRef(address)
  const [resetDate, setResetDate] = useState(0)

  // hack https://github.com/rainbow-me/rainbowkit/issues/686(react版本问题)
  useEffect(() => {
    if (!address && addressRef.current) {
      setResetDate(new Date().getTime())
    }
    addressRef.current = address
  }, [address])

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} key={`rainbowkit-${resetDate}`}>
        <Provider store={store}>
          <CacheProvider value={emotionCache}>
            <Head>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            </Head>
            <ThemeProvider theme={theme}>
              <CssBaseline />

              <AppWrapper >
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </AppWrapper>

            </ThemeProvider>
          </CacheProvider>
        </Provider>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}
