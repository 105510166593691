import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import url from '@/config/config'

const baseUrl = `${url.assetsUrl}`

const BaseIcon = (props) => {
  const {
    name,
    version = '0.2.3',
    trigger,
    width = 48,
    height = 38,
    sx,
    active = false,
    onClick,
    allName,
    activeName,
  } = props

  let icon
  let iconLight
  const [light, setLight] = useState(active || false)

  if (allName) {
    icon = `${baseUrl}${version}/${name}.svg`
    iconLight = `${baseUrl}${version}/${activeName}.svg`
  } else {
    icon = `${baseUrl}${version}/${name}.svg`
    iconLight = `${baseUrl}${version}/${name}_active.svg`
  }

  let event = {}

  switch (trigger) {
    case 'hover':
      event.onMouseOver = () => {
        setLight(true)
      }
      event.onMouseOut = () => {
        setLight(false)
      }
      break

    case 'click':
      event.onClick = () => {
        setLight(!active)
      }
      break

    default:
      break
  }

  useEffect(() => {
    if (active !== undefined) {
      setLight(active)
    }
  }, [active])

  return (
    <Box
      sx={{ display: 'inline-block', lineHeight: 0, ...sx }}
      onClick={onClick}
      {...event}
    >
      <img
        style={{ display: !light ? 'block' : 'none' }}
        src={icon} width={width} height={height} alt={name}
      />

      {trigger && (
        <img
          style={{ display: light ? 'block' : 'none' }}
          src={iconLight} width={width} height={height} alt={name}
        />
      )}
    </Box>
  )
}

const BasePngIcon = (props) => {
  const {
    name,
    version = '0.3.1',
    px = 3,
    trigger,
    width = 20,
    height = 20,
    sx,
    active = false,
    onClick,
    allName = false,
    normalName,
    activeName,
  } = props

  let icon, iconLight

  if (px && !allName) {
    icon = `${baseUrl}${version}/${name}@${px}x.png`
    iconLight = `${baseUrl}${version}/${name}_active@${px}x.png`
  } else {
    icon = `${baseUrl}${version}/${name}.png`
  }

  if (allName) {
    icon = `${baseUrl}${version}/${normalName}.png`
    iconLight = `${baseUrl}${version}/${activeName}.png`
  }

  const [light, setLight] = useState(active || false)

  let event = {}

  switch (trigger) {
    case 'hover':
      event.onMouseOver = () => {
        setLight(true)
      }
      event.onMouseOut = () => {
        setLight(false)
      }
      break

    case 'click':
      event.onClick = () => {
        setLight(!active)

        !!onClick && onClick()
      }
      break

    default:
      break
  }

  useEffect(() => {
    if (active !== undefined) {
      setLight(active)
    }
  }, [active])

  return (
    <Box
      sx={{ display: 'inline-block', lineHeight: 0, ...sx }}
      onClick={onClick}
      {...event}
    >
      <img
        style={{ display: !light ? 'block' : 'none' }}
        src={icon} width={width} height={height} alt={name}
      />

      {trigger && (
        <img
          style={{ display: light ? 'block' : 'none' }}
          src={iconLight} width={width} height={height} alt={name}
        />
      )}
    </Box>
  )
}

export const EthIcon = props => <BaseIcon name="eth" {...props}/>
export const WethIcon = props => <BaseIcon name="weth" {...props}/>
export const EthWIcon = props => <BasePngIcon allName={true} baseUrl="https://s3-nswap-base.nswap.com/" version="0.3.3" normalName="chain_10001" {...props}/>
export const BSCIcon = props => <BasePngIcon allName={true} baseUrl="https://s3-nswap-base.nswap.com/" version="0.3.3" normalName="bns_header@3x" {...props}/>
export const LikeIcon = props => <BaseIcon name="like" trigger="fake_click" {...props} />

export const WebIcon = props => <BaseIcon name="web" trigger="hover" version="0.3.1" {...props} />
export const TwitterIcon = props => <BaseIcon name="twitter" trigger="hover" {...props} />
export const DiscordIcon = props => <BaseIcon name="discord1" trigger="hover" {...props} />
export const InstagramIcon = props => <BaseIcon name="instragram" trigger="hover" {...props} />
export const SettingIcon = props => <BaseIcon name="setting" trigger="hover" {...props}/>

export const OptionsIcon = props => <BaseIcon name="options" {...props}/>
export const FilterIcon = props => <BaseIcon name="filter" {...props}/>
export const VerifiedIcon = props => <BasePngIcon name="cert" version="0.3.2" {...props} />
export const NewVerifiedIcon = props => <BaseIcon name="home_certified" version="0.3.4" {...props} />
export const EtherscanIcon = props => <BaseIcon name="etherscan" {...props} />
export const ProjectIcon = props => <BaseIcon name="project" {...props} />
export const UsersIcon = props => <BaseIcon name="users" {...props} />
export const CopyIcon = props => <BaseIcon name="copy" {...props}/>
export const DiamondIcon = props => <BaseIcon name="diamond" {...props}/>

export const AddIcon = props => <BasePngIcon name="add_active" {...props} />
export const RemoveIcon = props => <BasePngIcon name="remove" {...props} />
export const CalendarIcon = props => <BasePngIcon name="calendar" trigger="hover" {...props} />
export const UploadIcon = props => <BasePngIcon name="upload" trigger="" {...props} />
export const EditIcon = props => <BasePngIcon px={0} width={30} height={30} name="pencil" version="0.2.3" {...props} />

export const HoverTwitter = props => <BasePngIcon allName={true} normalName="twitter_normal@2x" activeName="twitter_active@2x" trigger="hover" width={36} height={36} {...props} />
export const HoverDiscord = props => <BasePngIcon allName={true} normalName="discord_normal@2x" activeName="discord_active@2x" trigger="hover" width={36} height={36} {...props} />
export const HoverNet = props => <BasePngIcon allName={true} normalName="net_normal@2x" activeName="net_active@2x" trigger="hover" width={36} height={36} {...props} />
export const HoverSet = props => <BasePngIcon allName={true} normalName="set_normal@2x" activeName="set_active@2x" trigger="hover" width={36} height={36} {...props} />

export const CheckLightIcon = props => <BaseIcon name="check_light" version="0.3.2" width={16} height={16} {...props} />
export const CheckIcon = props => <BaseIcon name="check" version="0.3.2" width={14} height={14} {...props} />
export const CloseIcon = props => <BaseIcon name="close" version="0.3.2" width={14} height={14} {...props} />
export const HourglassIcon = props => <BaseIcon name="hourglass" version="0.3.2" width={14} height={14} {...props} />
export const InfoIcon = props => <BaseIcon name="info" version="0.3.2" width={14} height={14} {...props} />
export const CloseGrayIcon = props => <BaseIcon name="close_gray" version="0.3.2" width={14} height={14} {...props} />
export const CompletedIcon = props => <BaseIcon name="complete" version="0.3.2" width={30} height={30} {...props}/>
export const MoneyIcon = props => <BasePngIcon px={3} width={22} height={22} name="money" version="0.3.2" {...props}/>

export const WebV2Icon = props => <BasePngIcon name="web_v2" width={48} height={48} trigger="" version="0.3.3" {...props} />
export const TwitterV2Icon = props => <BasePngIcon name="twitter_v2" width={48} height={48} trigger="" version="0.3.3" {...props} />
export const DiscordV2Icon = props => <BasePngIcon name="discord_v2" width={48} height={48} trigger="" version="0.3.3" {...props} />
export const EtherscanV2Icon = props => <BasePngIcon name="etherscan_v2" width={48} height={48} trigger="" version="0.3.3" {...props} />
export const LikeV2Icon = props => <BasePngIcon name="favorite_v2" width={48} height={48} trigger="fake_click" version="0.3.3" {...props} />
export const WebV3Icon = props => <BaseIcon name="net_item_normal" width={26} height={26} trigger="hover" allName={true} version="0.3.4" {...props} activeName="net_item_hover"/>
export const TwitterV3Icon = props => <BaseIcon name="twitter_item_noraml" width={26} height={26} trigger="hover" allName={true} version="0.3.4" {...props} activeName="twitter_item_hover" />
export const DiscordV3Icon = props => <BaseIcon name="dc_item_normal" width={26} height={26} trigger="hover" allName={true} version="0.3.4" {...props} activeName="dc_item_hover" />
export const EtherscanV3Icon = props => <BaseIcon name="eth_item_noraml" width={26} height={26} trigger="hover" allName={true} version="0.3.4" {...props} activeName="eth_item_hover" />
export const LikeV3Icon = props => <BaseIcon name="like_item_normal" width={26} height={26} trigger="fake_click" allName={true} version="0.3.4" {...props} activeName="like_item_hover" />
export const ColSettingIcon = props => <BaseIcon name="collection_setting" width={26} height={26} trigger="fake_click" version="0.3.3" {...props} />

export const InstagramV2Icon = props => <BasePngIcon name="ins_v2" width={48} height={48} trigger="" version="0.3.3" {...props} />
export const SettingV2Icon = props => <BasePngIcon name="setting_v2" width={48} height={48} trigger="" version="0.3.3" {...props} />

export const ExternalLinkIcon = props => <BasePngIcon px={3} width={20} height={20} name="externallink" version="0.3.3" trigger="hover" {...props}/>
export const SaleIcon = props => <BasePngIcon px={3} width={20} height={20} name="sale" version="0.3.3" trigger="fake_hover" {...props}/>
export const TransferIcon = props => <BasePngIcon px={3} width={20} height={20} name="transfer" version="0.3.3" trigger="fake_hover" {...props}/>
export const OfferIcon = props => <BasePngIcon px={3} width={20} height={20} name="offer" version="0.3.3" trigger="fake_hover" {...props}/>
export const CollectionOfferIcon = props => <BasePngIcon px={3} width={20} height={20} name="collection_offer" version="0.3.3" trigger="fake_hover" {...props}/>
export const ListingIcon = props => <BasePngIcon px={3} width={20} height={20} name="listing" version="0.3.3" trigger="fake_hover" {...props}/>

export const CheckboxIcon = props => <BasePngIcon px={3} width={12} height={12} name="checkbox" version="0.3.3" trigger="" {...props}/>
export const CheckedIcon = props => <BasePngIcon px={3} width={24} height={24} name="checked" version="0.3.3" trigger="" {...props}/>
export const CheckedIconSvg = props => <BaseIcon px={3} width={12} height={12} name="col_check_icon" version="0.3.4" trigger="" {...props}/>
export const CheckedDisabledIcon = props => <BasePngIcon px={3} width={24} height={24} name="checked_disabled" version="0.3.3" trigger="" {...props}/>
export const NsIcon = props => <BaseIcon px={3} width={24} height={24} name="nswap_filter_icon" version="0.3.4" trigger="" {...props}/>
export const OpenseaIcon = props => <BasePngIcon px={3} width={24} height={24} name="opensea_icon" version="0.3.3" trigger="" {...props}/>
export const X2y2Icon = props => <BasePngIcon px={3} width={24} height={24} name="x2y2_icon" version="0.3.3" trigger="" {...props}/>
export const NsIcon_12 = props => <BaseIcon px={3} width={12} height={12} name="nswap_filter_icon" version="0.3.4" trigger="" {...props}/>
export const OpenseaIcon_12 = props => <BasePngIcon px={3} width={12} height={12} name="opensea_icon" version="0.3.3" trigger="" {...props}/>
export const X2y2Icon_12 = props => <BasePngIcon px={3} width={12} height={12} name="x2y2_icon" version="0.3.3" trigger="" {...props}/>
export const NsIcon_ITEM = props => <BaseIcon px={3} width={18} height={18} name="ns_item_icon" version="0.3.4" trigger="" {...props}/>
export const OpenseaIcon_ITEM = props => <BaseIcon px={3} width={18} height={18} name="opensea_item" version="0.3.4" trigger="" {...props}/>
export const X2y2Icon_ITEM = props => <BaseIcon px={3} width={18} height={18} name="x2y2_item" version="0.3.4" trigger="" {...props}/>
export const BlurIcon_ITEM = props => <BaseIcon px={3} width={18} height={18} name="blur_item" version="0.3.4" trigger="" {...props}/>

export const LooksrareIcon = props => <BasePngIcon px={3} width={24} height={24} name="looksrare_icon" version="0.3.3" trigger="" {...props}/>
export const BlurIcon = props => <BasePngIcon px={0} width={24} height={24} name="blur_72" version="0.3.4" trigger="" {...props}/>
export const ListClose = props => <BasePngIcon px={3} width={16} height={16} name="listings_close" version="0.3.3" trigger="" {...props}/>
export const ListError = props => <BasePngIcon px={3} width={16} height={16} name="listings_att" version="0.3.3" trigger="" {...props}/>
export const ListErrorTwo = props => <BasePngIcon px={3} width={16} height={16} name="listings_att_two" version="0.3.3" trigger="" {...props}/>
export const ListSucc = props => <BasePngIcon px={3} width={16} height={16} name="listings_success" version="0.3.3" trigger="" {...props}/>
export const OpenseaBan = props => <BasePngIcon px={3} width={16} height={16} name="opensea_ban" version="0.3.3" trigger="" {...props}/>
// export const EthIcon = props => <BasePngIcon px={3} width={16} height={16} name="eth" version="0.3.3" trigger="" {...props}/>

export default BaseIcon