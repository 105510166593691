import { getChainInfo } from '@/utils/networks'
import { EnvType } from './constant'
import { ITEM_DEFAULT_URI } from '@utils/img'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getChainCurrencyName } from '@utils/networks.js'

export function tranNumber(num, point) {
  const numStr = num.toString().split('.')[0]
  if (numStr.length < 4) {
    return numStr
  } else if (numStr.length >= 4 && numStr.length <= 6) {
    const decimal = numStr.substring(numStr.length - 3, numStr.length - 3 + point)
    return `${parseFloat(`${parseInt(num / 1000)}.${decimal}`)}k`
  } else if (numStr.length > 6) {
    const decimal = numStr.substring(numStr.length - 6, numStr.length - 6 + point)
    return `${parseFloat(`${parseInt(num / 1000000)}.${decimal}`)}m`
  }
}

export function formatSimpleDate(data) {
  const date = new Date(data)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? `0${m}` : m
  let d = date.getDate()
  d = d < 10 ? `0${d}` : d
  return `${m}:${d}:${y}`
}

export function formatDate(data) {
  const date = new Date(data)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? `0${m}` : m
  let d = date.getDate()
  d = d < 10 ? `0${d}` : d
  let h = date.getHours()
  h = h < 10 ? `0${h}` : h
  let minute = date.getMinutes()
  minute = minute < 10 ? `0${minute}` : minute
  let second = date.getSeconds()
  second = second < 10 ? `0${second}` : second
  return `${y}-${m}-${d} ${h}:${minute}:${second}`
}

export function compareDate(date) {
  const formateDate = new Date(date / 1000000)
  const curDate = new Date()
  if (formateDate.getTime() - curDate.getTime() < 0) {
    return 'two'
  }
  return 'one'

}

export function formatPrice(n) {
  let num = n.toString() // 转成字符串
  let decimals = ''
  // 判断是否有小数
  num.indexOf('.') > -1 ? (decimals = num.split('.')[1]) : decimals
  let len = num.length
  if (len <= 3) {
    return num
  }
  let temp = ''
  let remainder = len % 3
  decimals ? temp = `.${decimals}` : temp
  if (remainder > 0) { // 不是3的整数倍
    // eslint-disable-next-line no-console
    console.log(num.slice(remainder, len), '-----')
    return `${num.slice(0, remainder)},${num
      .slice(remainder, len)
      .match(/\d{3}/g)
      .join(',')}${temp}`
  } // 是3的整数倍
  return num.slice(0, len).match(/\d{3}/g)
    .join(',') + temp

}

const isClient = typeof window !== 'undefined'

export function setItem(key, val) {
  isClient && localStorage.setItem(key, JSON.stringify(val) || {})
}

export function getItem(key) {
  let storageContent
  try {
    storageContent = isClient && JSON.parse(localStorage.getItem(key)) || {} //TODO 坑，值为0，返回{}
  } catch (error) {
    storageContent = {}
  }

  return storageContent
}

export function deleteItem(key) {
  try {
    storageContent = isClient && JSON.parse(localStorage.getItem(key)) || {}
    if (Object.keys(storageContent).length > 0) {
      delete storageContent[key]
      setItem(key, storageContent)
    }
  } catch (error) {

  }
}

export function removeItem(key) {
  localStorage.removeItem(key)
}

export const ellipsisAddress = (address) => {
  const len = address?.length || 0
  let _address = ''

  if (typeof address === 'string' && len > 12) {
    let head = address.substring(0, 6)
    let tail = address.substring(len - 4, len)

    _address = `${head}...${tail}`
  } else {
    _address = address
  }

  return _address
}

export const copy = (value, callback) => {
  const textarea = document.createElement('textarea')
  document.body.appendChild(textarea)
  textarea.value = value
  textarea.select()

  if (document.execCommand('copy')) {
    document.execCommand('copy')
    !!callback && callback()
  }

  document.body.removeChild(textarea)
}

export const gotoEtherscan = (hash, txOrAddress) => {
  if (!!hash) {
    const link = `https://etherscan.io/${txOrAddress}/${hash}`
    window.open(link, '_blank')
  }
}

export const getEnv = () => {
  const { NEXT_PUBLIC_ENV } = process.env
  // if (NEXT_PUBLIC_ENV) {
  //   for (const key in EnvType) {
  //     const env = EnvType[key]
  //     if (env === NEXT_PUBLIC_ENV) {
  //       return NEXT_PUBLIC_ENV
  //     }
  //   }
  // }
  if (NEXT_PUBLIC_ENV) {
    return `${NEXT_PUBLIC_ENV}Nswap`
  }
  return EnvType.PRODUCTION
}

export const isProduction = () => {
  const env = getEnv()
  if (env.indexOf('productionUniquelyIdentifies') >= 0) {
    return true
  }
  return false
}

export const checkUrlHost = () => {
  const { hostname } = window.location || { hostname: null }
  if (hostname === 'www.nswap.works') {
    return EnvType.PRODUCTION
  }
  if (['base.dev.nswap.works', 'dev.nswap.works', 'localhost'].includes(hostname)) {
    return EnvType.TEST
  }
  return EnvType.PRODUCTION
}

export function browserRedirect() {
  let sUserAgent = navigator.userAgent.toLowerCase()
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
  //go iphone
    return true
  }
  //go pc
  return false

}

export function numFormatter(num) {
  if (num < 1 && num > 0) {
    return Number(num).toFixed(4)
  }
  let value = num
  if (`${num}`.includes('.')) {
    value = Number(value).toFixed(2)
  }
  const numArr = `${value}`.split('.')
  const decimal = numArr[1]
  let integer = numArr[0]
  if (integer.length > 3) {
    integer = integer.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }
  return `${integer}${decimal ? `.${ decimal}` : ''}`
}

const ipfsDomain = [
  'https://cf-ipfs.com/ipfs/',
  'https://ipfs.infura.io/ipfs/',
  'https://gateway.pinata.cloud/ipfs/',
  'https://ipfs.pixura.io/ipfs/',
  'https://ipfs.daonomic.com/ipfs/',
  'https://www.via0.com/ipfs/',
]

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min)) + min
}

export const imgOnError = (e) => {
  const src = e.target?.getAttribute('src')
  // 如果小图(330X300 || 48X48)报错，路径强制去掉（就是大图路径）
  if (src.indexOf('/48x48/') > -1 ) {
    const partArr = src.split('/48x48/')
    const newSrc = `${partArr[0]}/${partArr[1]}`

    e.currentTarget.onerror = null
    e.currentTarget.src = newSrc
    e.currentTarget.className = 'error'
    return
  }

  if (src.indexOf('/330x330/') > -1 ) {
    const partArr = src.split('/330x330/')
    const newSrc = `${partArr[0]}/${partArr[1]}`
    console.log(111, newSrc)
    e.currentTarget.onerror = null
    e.currentTarget.src = newSrc
    e.currentTarget.className = 'error'
    return
  }

  // 如果是ipfs的图片资源报错
  const ipfsItem = ipfsDomain.find(item => src.startsWith(item))
  if (ipfsItem) {
    const newIpfsDomain = ipfsDomain.filter(item => item !== ipfsItem)
    const newSrc = newIpfsDomain[getRandomNumber(0, newIpfsDomain.length - 1)] + src.split('/ipfs/')[1]

    e.currentTarget.onerror = null
    e.currentTarget.src = newSrc
    e.currentTarget.className = 'error'
    return
  }

  //默认
  e.currentTarget.onerror = null
  e.currentTarget.src = ITEM_DEFAULT_URI
  e.currentTarget.className = 'error'
}

export const preLoadImg = (imgArr) => {
  let promiseAll = imgArr.map((item) => {
    return new Promise(function (resolve, reject) {
      let img = new Image()
      img.onload = function () {
        img.onload = null
        resolve(img)
      }
      img.error = function () {
        reject('img error')
      }
      img.src = item
    })
  })
  return promiseAll
}

export const maxDecimal = (number) => {
  return String(number).replace(/^(.*\..{4}).*$/, '$1')
}

export const getMetamaskUrl = (url) => {
  if (!url) return ''
  return `https://metamask.app.link/dapp/${url}`
}

export const openCustomMetamask = (url) => {
  const newUrl = getMetamaskUrl(url)
  if (newUrl) {
    window.open(newUrl)
  }
}

export const openMetamaskApp = () => {
  const url = window.location.hostname + window.location.pathname + window.location.search
  const newUrl = getMetamaskUrl(url)
  console.log(newUrl, 'newUrl--')
  if (typeof window.ethereum !== 'undefined') {
    console.log('Ethereum Provider is existed!')
    if (window.ethereum.isMetaMask) {
      console.log('This is MetaMask!')
      return true
    }
  } else {
    if (newUrl) {
      window.open(newUrl)
    }
  }
}

export const inMetamaskApp = () => {
  if (typeof window.ethereum !== 'undefined') {
    console.log('Ethereum Provider is existed!')
    if (window.ethereum.isMetaMask) {
      console.log('This is MetaMask!')
      return true
    }
  } else {
    return false
  }
}

export const numFormate = (num, precision = 4) => {
  return +parseFloat(num.toPrecision(precision))
}

// num为number类型即可生效
export const numLocalFormate = (num) => {
  let digits = 0
  if (num % 1 !== 0) {
    let numStr = String(num)
    let decimal = numStr.split('.')[1]
    digits = decimal.length || 0
    return num.toLocaleString('en-US', {
      minimumFractionDigits: digits,
    })
  }
  return num.toLocaleString('en-US', {
    minimumFractionDigits: digits,
  })
}

export const parseJson = (data, type = '{}') => {
  let result = ''
  try {
    result = JSON.parse(data)
  } catch (error) {
    console.log(error, '====JSON.parse===')
    result = JSON.parse(type)
  }
  return result
}

export function setSessionItem(key, val) {
  isClient && sessionStorage.setItem(key, val)
}

export function getSessionItem(key) {
  let storageContent
  try {
    storageContent = isClient && sessionStorage.getItem(key)
  } catch (error) {
    storageContent = {}
  }

  return storageContent
}

export function removeSessionItem(key) {
  isClient && sessionStorage.removeItem(key)
}

export function getChainName(chainId) {
  let chainName = 'eth'
  try {
    const _chainId = chainId || localStorage.getItem('chain')
    chainName = getChainInfo(_chainId)?.chainName?.toLocaleLowerCase()
  } catch (error) {
    console.log(error)
  }
  return chainName
}

export function isWeixin() {
  let ua = navigator.userAgent.toLowerCase()
  let isWeixin = ua.indexOf('micromessenger') !== -1
  if (isWeixin) {
    return true
  }
  return false
}

export function usePhone () {
  return useMediaQuery('(max-width:750px)')
}

export function formatPriceWithChain (price, chain) {
  if (!price && price !== 0) return ''
  let numPrice
  if (typeof price === 'number') {
    numPrice = price.toFixed(4)
  }

  if (typeof price === 'string') {
    numPrice = Number(price).toFixed(4)
  }
  return `${Number(numPrice).toLocaleString()} ${getChainCurrencyName(chain)}`

}