import { getItem } from '@utils/utils'
import { isEmpty } from 'lodash-es'
import { useEffect, useReducer } from 'react'

const initialState = {
  loading: false,
  cart: [],
  finalCart: [], //确认交易的cart
  expired: [],
  orders: {},

  success: [],
  fail: [],

  open: false,
  approve: false,
  feedback: false,

  max: 99,
}

function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return { ...state, loading: action.payload }

    case 'update':
      return { ...state, cart: action.payload }

    case 'removeAll':
      return { ...state, cart: [] }

    case 'expired':
      return { ...state, expired: action.payload }

    case 'orders':
      return { ...state, orders: action.payload }

    case 'visible':
      return { ...state, open: action.payload }

    case 'approve':
      return { ...state, approve: action.payload }

    case 'feedback':
      return { ...state, feedback: action.payload }

    case 'all':
      return { ...state, ...action.payload }

    case 'updataFinalCart':
      return { ...state, finalCart: action.payload }

    case 'reset':
      return initialState
  }
}

const useCart = () => {
  let cart = getItem('cart')
  cart = isEmpty(cart) ? [] : cart

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'update', payload: cart })
  }, [])

  return { cartState: state, cartDispatch: dispatch }
}

export default useCart