import { rgba } from 'polished'

export default function componentStyleOverrides() {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
        input: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '46px',
          lineHeight: '24px',
          '&.MuiTab-root': {
            color: '#000',
            opacity: 0.6,
            fontSize: 16,
            fontWeight: 600,
            textTransform: 'capitalize',
            '.MuiInputBase-input': {
              paddingTop: 0,
              paddingBottom: 0,
            },
            '&.Mui-selected': {
              color: '#000000',
              opacity: 1,
              '.MuiOutlinedInput-root': {
                color: '#5200FF',
              },
            },
          },
          '& .MuiButtonBase-root': {
            minHeight: '46px',
            padding: '18px 16px 4px',
            lineHeight: '24px',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          // height: 0
          backgroundColor: '#000000',
        },
        root: {
          '&.MuiTabs-indicator': {
            // height: 0,
          },
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: {
          borderRadius: 8,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000',
          fontSize: 12,
          fontWeight: 500,
          '&:hover': {
            backgroundColor: rgba(20, 0, 255, 0.1),
            color: '#5200FF',
          },
          '&&.Mui-selected': {
            color: '#5200FF',
            backgroundColor: '#1400ff1a',
            '&:hover': {
              backgroundColor: rgba(20, 0, 255, 0.1),
            },
          },
        },
      },
    },
  }
}
