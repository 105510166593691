import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
import componentStyleOverrides from './compStyleOverride'
// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: '#5200FF',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
})
theme.components = componentStyleOverrides(theme)
export default theme
