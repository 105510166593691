import {
  NsIcon,
  OpenseaIcon,
  X2y2Icon,
  LooksrareIcon,
  BlurIcon,
  NsIcon_ITEM,
  OpenseaIcon_ITEM,
  X2y2Icon_ITEM,
  BlurIcon_ITEM,
} from '@/components/Icons'

// 交易操作类型
export const LISTTING = 'listting'
export const CANCEL_LISTTING = 'cancelListting'
export const MAKE_OFFER = 'makeOffer'
export const CANCEL_OFFER = 'cancelOffer'
export const BUY = 'buy'
export const ACCEPT_OFFER = 'acceptOffer'
export const LOWER_PRICE = 'lowerPrice'

export const TYPE_MAP_DATA = {
  [ACCEPT_OFFER]: { title: 'Accept offer' },
  [BUY]: { title: 'Buy Now' },
  [LISTTING]: { title: 'List for Sale' },
  [LOWER_PRICE]: { title: 'Lower Price' },
  [CANCEL_LISTTING]: { title: 'Cancel Listing' },
  [MAKE_OFFER]: { title: 'Make Offer' },
  [CANCEL_OFFER]: { title: 'Cancel Offer' },
}

export const TradeErrorText = {
  invalidNum: 'Invalid number. Please reenter.',
  offerHigher:
    'Your offer exceeds the current price(61ETH). Please reenter.',
  offerEqual:
    'Your offer price is equal to the current price(61ETH). Please reenter.',
  wethNoEnough:
    'You do not have enough funds in your wallet to complete your purchase.',
  lowerPriceHigherLast:
    'Your price needs to be lower than the last listed price.',
  lowerPriceEqualHighOffer:
    'Your price is equal to the current highest offer price(61 ETH).',
  lowerPricelessHightOffer:
    'Your price is lower than the current highest offer price(61 ETH).',
}
export const getErrorText = ({ price, error }) => {
  const TradeErrorText = {
    invalidNum: 'Invalid number, Please reenter.',
    offerHigher: `Your offer exceeds the current price(${price}ETH). Please reenter.`,
    offerEqual: `Your offer price is equal to the current price(${price}ETH). Please reenter.`,
    wethNoEnough:
      'You do not have enough funds in your wallet to complete your purchase.',
    lowerPriceHigherLast:
      'Your price needs to be lower than the last listed price.',
    lowerPriceEqualHighOffer: `Your price is equal to the current highest offer price(${price} ETH). `,
    lowerPricelessHightOffer: `Your price is lower than the current highest offer price(${price} ETH). `,
  }
  return TradeErrorText[error]
}

export const EnvType = {
  LOCAL: 'local',
  TEST: 'test',
  PRODUCTION: 'productionUniquelyIdentifiesNswap',
}

export const WENS_DOMAIN = ['.ethw', '.awsb', '.wens', '.pow', '.ape', '.twit']

export const CHAINTEXT = {
  1: 'ETH',
  56: 'BNB',
  10001: 'ETHW',
}

export const textObj = {
  1: 'Ethereum',
  56: 'BSC',
  10001: 'ETHW',
}

export const CurrencyText = {
  1: 'Ethereum',
  56: 'BNB',
  10001: 'ETHW',
}

export const ExploreText = {
  1: 'ETH',
  56: 'BSC',
  10001: 'ETHW',
}

export const DOMAIN = {
  1: {
    pro: 'works',
    online: 'com',
  },
  10001: {
    pro: 'network',
    online: 'link',
  },
  56: {
    pro: 'website',
    online: 'pro',
  },
}

export const METAVERSE = 'metaverse'
export const METATWIT = 'metatwit'

export const SPACEA_ARR = [{
  id: 1,
  spaceName: METAVERSE,
  imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/explore_1@2x.png',
}, {
  id: 2,
  spaceName: METATWIT,
  imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/explore_2@2x.png',
},
{
  id: 5,
  imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/coming_soon_200.png',
}]

export const FACTORY_ARR = [{
  id: 3,
  imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/ex_2.png',
  chain: '56',
  bgColor: '#F0B90B',
  path: '/robot',
  onlyPc: true,
},
{
  id: 4,
  imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/ex_1.png',
  chain: '10001',
  bgColor: '#5856D6',
  path: '/snaili',
  onlyPc: true,
},
{
  id: 6,
  imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/ex_3.png',
  chain: '',
  path: '',
},
]

export const ROBOT_ERROR_TEXT = {
  11519: 'Item parts are not enough.',
  11520: 'Item parts are not the same color.',
  11521: 'You need 6 different parts with the same color to get Lucci.',
  11502: 'Item owner changed',
}

export const Nswap = 'Nswap'
export const Opensea = 'Opensea'
export const Looksrare = 'Looksrare'
export const X2Y2 = 'X2Y2'
export const Blur = 'Blur'

export const MARKET_PLACE_MAP = {
  Nswap: <NsIcon/>,
  Opensea: <OpenseaIcon/>,
  Looksrare: <LooksrareIcon/>,
  X2Y2: <X2y2Icon/>,
  Blur: <BlurIcon/>,
}

const getMarketIcon = (width, height) => {
  return {
    Nswap: <NsIcon width={width} height={height} />,
    Opensea: <OpenseaIcon width={width} height={height} />,
    Looksrare: <LooksrareIcon width={width} height={height} />,
    X2Y2: <X2y2Icon width={width} height={height} />,
    Blur: <BlurIcon width={width} height={height} />,
  }
}

export const MARKET_PLACE_16_MAP = getMarketIcon(16, 16)
export const MARKET_PLACE_20_MAP = getMarketIcon(20, 20)
export const BEST_MARKET = {
  0: Nswap,
  1: Opensea,
  2: Looksrare,
  3: X2Y2,
  5: Blur,
}
export const MARKET_NUM = {
  [Nswap]: 0,
  [Opensea]: 1,
  [Looksrare]: 2,
  [X2Y2]: 3,
  [Blur]: 5,
}

export const BEST_MARKET_ICON = {
  0: MARKET_PLACE_20_MAP[Nswap],
  1: MARKET_PLACE_20_MAP[Opensea],
  2: MARKET_PLACE_20_MAP[Looksrare],
  3: MARKET_PLACE_20_MAP[X2Y2],
  5: MARKET_PLACE_20_MAP[Blur],
}
export const BEST_MARKET_16_ICON = {
  0: MARKET_PLACE_16_MAP[Nswap],
  1: MARKET_PLACE_16_MAP[Opensea],
  2: MARKET_PLACE_16_MAP[Looksrare],
  3: MARKET_PLACE_16_MAP[X2Y2],
  5: MARKET_PLACE_20_MAP[Blur],
}

export const MARKET_PLACE_ITEM_MAP = {
  0: <NsIcon_ITEM/>,
  1: <OpenseaIcon_ITEM/>,
  2: <LooksrareIcon/>,
  3: <X2y2Icon_ITEM/>,
  5: <BlurIcon_ITEM/>,
}

// kol数据

export const KOL_DATA = [
  {
    name: 'LaserCat397.eth',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/0tllf1zi_400x400.jpeg',
    nameId: '@BitCloutCat',
    desc: 'Founder of LaserCat',
    link: 'https://twitter.com/BitCloutCat',
  },
  {
    name: '0xLeon',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/z5n0xijg_400x400.jpeg',
    nameId: '@Leoninweb3',
    desc: 'Founder of CrossSpace',
    link: 'https://twitter.com/Leoninweb3',
  },
  {
    name: '0xSun',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/xwatjmpk_400x400.png',
    nameId: '@0xSunNFT',
    desc: 'Founder of 0xSun group - A Community for NFT Degen ',
    link: 'https://twitter.com/0xSunNFT',
  },
  {
    name: 'Daniel老多',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/kiyqmugb_400x400.jpeg',
    nameId: '@0xDanielWeb3',
    desc: 'Co-founder & Host of Web3 Gems on RugRadio',
    link: 'https://twitter.com/0xDanielWeb3',
  },
  {
    name: 'MARK',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/pjxhudie_400x400.jpeg',
    nameId: '@shuaibaobaommd',
    desc: 'Web3资深玩家，Opensky社区创始人',
    link: 'https://twitter.com/shuaibaobaommd',
  },
  {
    name: 'Goku很Cool',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/eyqv0sfe_400x400.jpeg',
    nameId: '@gokunocool',
    desc: 'Web3最会整活儿的男人 资深顾问 投资者',
    link: 'https://twitter.com/gokunocool',
  },
  {
    name: 'Kirara',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/ovr2bcke_400x400.png',
    nameId: '@0xKirara',
    desc: 'crypto cosplay资深玩家',
    link: 'https://twitter.com/0xKirara',
  },
  {
    name: '潜水观察员',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/f1rbmlbp_400x400.png',
    nameId: '@connectfarm1',
    desc: 'InFuture发起人',
    link: 'https://twitter.com/connectfarm1',
  },
  {
    name: 'WaTell',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/dfr-lapb_400x400.png',
    nameId: '@0xWatell',
    desc: 'NFT线程研究员',
    link: 'https://twitter.com/0xWatell',
  },
  {
    name: 'Mini_MiSue',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/9nzlhgnd_400x400.jpeg',
    nameId: '@minimisue2022',
    desc: 'Founder of  MiMiAlpha',
    link: 'https://twitter.com/minimisue2022',
  },
  {
    name: '区块链猫神',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/majsj966_400x400.jpeg',
    nameId: '@Samcoincoin',
    desc: 'co-founder of Opensky',
    link: 'https://twitter.com/Samcoincoin',
  },
  {
    name: 'Sunny ｜WenDAO',
    avatar: 'https://s3-nswap-base.nswap.com/0.3.4/n_7aokg4_400x400.jpeg',
    nameId: '@SunnyNswap',
    desc: 'Founder of Wendao Co-Founder nswapcom',
    link: 'https://twitter.com/SunnyNswap',
  },
]

// Community数据

export const CommunityData = [
  // {
  //   name: 'WenDAO',
  //   twitter: 'https://twitter.com/WenDAO_2023',
  //   logo: 'https://s3-nswap-base.nswap.com/0.3.4/j3gmpnk6_400x400.jpeg',
  //   desc: '由Sunny发起，基于NFT的信息交流，资源共享的DAO组织',
  // },
  {
    name: 'a KID called BEAST中文',
    twitter: 'https://twitter.com/akcbcn',
    logo: 'https://s3-nswap-base.nswap.com/0.3.4/iz0txbp5_400x400.jpeg',
    desc: 'a KID called BEAST官方中文账号，旨在给中国用户同步AKCB信息',
  },
  {
    name: '0N1 原力社区',
    twitter: 'https://twitter.com/0N1ForceCN',
    logo: 'https://s3-nswap-base.nswap.com/0.3.4/gzgndfgl_400x400.jpeg',
    desc: '0n1Force官方中文社区',
  },
  {
    name: 'KPR',
    twitter: 'https://twitter.com/KPRVERSE',
    logo: 'https://s3-nswap-base.nswap.com/0.3.4/l4mro6re_400x400.jpeg',
    desc: '',
  },
  {
    name: 'WangHai Village',
    twitter: 'https://twitter.com/WangHaiVillage',
    logo: 'https://s3-nswap-base.nswap.com/0.3.4/sjqznxwe_400x400.jpeg',
    desc: 'The Story of Wanghai Village ',
  },
  {
    name: 'NounsChinese',
    twitter: 'https://twitter.com/Nounscn',
    logo: 'https://s3-nswap-base.nswap.com/0.3.4/eai6kmzz_400x400.jpeg',
    desc: '建立一个说中文的Nounish社区',
  },
  // {
  //   name: 'Dr.Ji',
  //   twitter: 'https://twitter.com/drjiclub',
  //   logo: 'https://pbs.twimg.com/profile_images/1545388292749008896/oPILLsnF_400x400.jpg',
  // },
  // {
  //   name: 'Theirsverse',
  //   twitter: 'https://twitter.com/Theirsverse',
  //   logo: 'https://pbs.twimg.com/profile_images/1612686998858252288/N6lJXE7-_400x400.jpg',
  // },
  // {
  //   name: 'NFTSTAR',
  //   twitter: 'https://twitter.com/THENFTSTAR',
  //   logo: 'https://pbs.twimg.com/profile_images/1560204419018280960/52YUPYgE_400x400.png',
  // },
]

// 首页 Resource数据

export const defaultList = [
  {
    title: 'What Is Nswap?',
    url: 'https://medium.com/nswap/what-is-nswap-92cb35fb830',
    imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/resource1.png',
  },
  {
    title: 'How to Buy an NFT on Nswap?',
    url: 'https://medium.com/nswap/how-to-buy-an-nft-on-nswap-b2fa245a56ea',
    imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/resource2.png',
  },
  {
    title: 'How to Create a Free NFT Raffle Using Nswap Raffles?',
    url: 'https://medium.com/nswap/how-to-create-a-free-nft-raffle-on-nswap-404dd69f3e41',
    imgUrl: 'https://s3-nswap-base.nswap.com/0.3.3/resource3.png',
  },
  {
    title: 'How to Sell an NFT on Nswap?',
    url: 'https://medium.com/nswap/how-to-sell-an-nft-on-nswap-737c50a3c4d4',
    imgUrl: 'https://s3-nswap-base.nswap.com/0.3.4/resource4.png',
  },
]