import { CHAIN1, CHAIN10001, CHAIN56, EtherscanV2Icon } from '@utils/img'

export const NET_WORKS_IDS = {
  ETH: 1,
  BSC: 56,
  ETHW: 10001,
  IPO: 101,
  eth: 1,
  bsc: 56,
  ethw: 10001,
  ipo: 101,
}

export const NET_WORKS = {
  [NET_WORKS_IDS.ETH]: {
    chainName: 'ETH',
    chainName_full: 'Ethereum', //全拼
    chainId: NET_WORKS_IDS.ETH,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    url: CHAIN1,
    logo: CHAIN1,
    scanLogo: EtherscanV2Icon,
    blockExplorerUrls: ['https://etherscan.io'], //多链钱包需要的数组
    themeColor: {
      color: '#1d9bf0',
      border: '1px solid #1d9bf0',
    },
    compaign_chain_url: 'https://s3-nswap-base.nswap.com/0.3.4/new_eth_icon.svg',
  },
  [NET_WORKS_IDS.BSC]: {
    chainName: 'BSC',
    chainName_full: 'BNB Chain', //全拼
    chainId: NET_WORKS_IDS.BSC,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    url: CHAIN56,
    logo: CHAIN56,
    scanLogo: CHAIN56,
    blockExplorerUrls: ['https://bscscan.com'],
    themeColor: {
      color: '#f0b90c',
      border: '1px solid #f0b90c',
    },
    compaign_chain_url: 'https://s3-nswap-base.nswap.com/0.3.3/bsc_icon.png',
  },
  [NET_WORKS_IDS.ETHW]: {
    chainName: 'ETHW',
    chainName_full: 'EthereumPOW', //全拼
    chainId: NET_WORKS_IDS.ETHW,
    nativeCurrency: {
      name: 'ETHW',
      symbol: 'ETHW',
      decimals: 18,
    },
    url: CHAIN10001,
    logo: CHAIN10001,
    scanLogo: CHAIN10001,
    blockExplorerUrls: ['https://www.oklink.com/en/ethw'],
    themeColor: {
      color: '#5200ff',
      border: '1px solid #5200ff',
    },
    compaign_chain_url: 'https://s3-nswap-base.nswap.com/0.3.3/ethw_compaign_icon.png',
  },
  [NET_WORKS_IDS.IPO]: {
    chainName: 'IPO',
    chainName_full: 'IPOLLO', //全拼
    chainId: NET_WORKS_IDS.IPO,
    nativeCurrency: {
      name: 'IPO',
      symbol: 'IPO',
      decimals: 18,
    },
    url: 'https://s3-nswap-base.nswap.com/0.3.3/ipo.png',
    logo: 'https://s3-nswap-base.nswap.com/0.3.3/ipo.png',
    blockExplorerUrls: ['https://etherscan.io'],
    themeColor: {
      color: '##2EE59E',
      border: '1px solid ##2EE59E',
    },
    compaign_chain_url: 'https://s3-nswap-base.nswap.com/0.3.3/ipo.png',
  },
}

export const getChainInfo = (chainId) => {
  return NET_WORKS[chainId] || {}
}

export const getChainName = (chainId) => {
  return NET_WORKS[chainId]?.chainName || ''
}

export const getChainCurrencyName = (chainId) => {
  return NET_WORKS[chainId]?.nativeCurrency?.name || ''
}

export const getChainBlockExplorerUrl = (chainId) => {
  return NET_WORKS[chainId]?.blockExplorerUrls?.[0] || ''
}

export const getChainLogo = (chainId) => {
  return NET_WORKS[chainId]?.logo
}

export const getChainScanLogo = (chainId) => {
  return NET_WORKS[chainId]?.scanLogo
}

//获取链主题色
export const getChainThemeColor = (chainId) => {
  return NET_WORKS[chainId]?.themeColor || {}
}

/**
 * 主要是下拉列表需要展示的选项
 * chainIds 可以根据自定义选择所需的
*/
export const getChainOptions = (chainIds = []) => {
  const defaultChainIds = [NET_WORKS_IDS.ETH, NET_WORKS_IDS.ETHW, NET_WORKS_IDS.BSC, NET_WORKS_IDS.IPO]
  return [...defaultChainIds, ...chainIds].map((chainId) => {
    return {
      value: chainId,
      label: NET_WORKS[chainId]?.chainName,
      source: NET_WORKS[chainId],
      chainImg: NET_WORKS[chainId].compaign_chain_url,
    }
  })
}

//给wagmi使用 https://wagmi.sh/react/chains
export const ethwChain = {
  id: 10001,
  name: 'ethw',
  network: 'ethw',
  nativeCurrency: {
    decimals: 18,
    name: 'ETHW',
    symbol: 'ETHW',
  },
  rpcUrls: {
    public: { http: ['https://mainnet.ethereumpow.org'] },
    default: { http: ['https://mainnet.ethereumpow.org'] },
  },
  blockExplorers: {
    etherscan: { name: 'SnowTrace', url: 'https://mainnet.ethwscan.com' },
    default: { name: 'SnowTrace', url: 'https://mainnet.ethwscan.com' },
  },
}

export const ipoChain = {
  id: 101,
  name: 'IPO',
  network: 'IPO',
  nativeCurrency: {
    decimals: 18,
    name: 'IPO',
    symbol: 'IPO',
  },
  rpcUrls: {
    public: { http: [' https://rpctest.ipolloverse.cn'] },
    default: { http: [' https://rpctest.ipolloverse.cn'] },
  },
  blockExplorers: {
    etherscan: { name: 'SnowTrace', url: 'https://etherscan.io' },
    default: { name: 'SnowTrace', url: 'https://etherscan.io' },
  },
}

